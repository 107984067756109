'use client'

import { LicenseInfo } from '@mui/x-license'

// https://mui.com/x/introduction/licensing/#next-js-app-router
LicenseInfo.setLicenseKey(
  'abbc853da44a47ce36d6bc37e991c32bTz05MjA3OCxFPTE3NDkzNzAxMTQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

export default function MuiXLicense() {
  return null
}
